.promo__text-container {
    position: relative;
    z-index: 10;
    max-width: 650px;
}

.promo__title {
    text-align: left;
    font-weight: 500;
    font-size: 50px;
    line-height: 115%;
    background: linear-gradient(to right, var(--gray-400), var(--gray-300));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin: 0 0 0;
}

.promo__title+.promo__subtitle {
    margin-bottom: 41px;
}

.promo__subtitle {
    text-align: left;
    font-weight: 300;
    font-size: 20px;
    line-height: 145%;
    color: var(--gray-600);
    margin: 20px 0;
}

.promo__image-container {
    position: absolute;
    right: -13.5%;
    bottom: 0;
}

.promo__bg-ellipse {
    right: -13.5%;
    bottom: 0;
    width: 440px;
    height: 440px;
    background-image: url(../../images/promo__ellipse.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 0;
}

.promo__image {
    z-index: 1;
    position: absolute;
    right: 0;
}

.promo__image_type_main {
    bottom: 0;
    max-width: 470px;
}

.promo__image_type_practice-area {
    width: 100%;
    top: 0;
    opacity: 0.05;
}

@media screen and (max-width: 1450px) {
    .promo__text-container {
        max-width: 600px;
    }

    .promo__title {
        font-size: 46px;
    }

    .promo__title+.promo__subtitle {
        margin-bottom: 20px;
    }

    .promo__bg-ellipse {
        width: 380px;
        height: 380px;
    }

    .promo__image-container {
        right: -13.5%;
    }
}

@media screen and (max-width: 1180px) {
    .promo__text-container {
        max-width: 550px;
    }

    .promo__title {
        font-size: 42px;
    }

    .promo__subtitle {
        font-size: 18px;
    }

    .promo__bg-ellipse {
        width: 350px;
        height: 350px;
    }
}

@media screen and (max-width: 1024px) {
    .promo__image_type_main {
        height: 365px;
    }
}

@media screen and (max-width: 900px) {
    .promo__text-container {
        max-width: none;
    }

    .promo__image-container {
        padding-top: 40px;
        width: 300px;
        position: relative;
        right: 0;
    }

    .promo__bg-ellipse {
        position: static;
        border-radius: 50%;
        width: 350px;
        height: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .promo__image {
        width: 400px;
        position: relative;
        bottom: 0;
        left: 1%;
        height: 100%;
        max-height: 370px;
    }


    .promo__image_type_practice-area {
        opacity: 1;
    }

    .promo__title {
        text-align: center;
        font-size: 36px;
        margin-bottom: 15px;
    }

    .promo__title + .promo__subtitle {
        margin-top: 0;
        margin-bottom: 30px;
    }

    .promo__subtitle {
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .promo__title {
        font-size: 28px;
    }

    .promo__bg-ellipse {
        width: 280px;
        height: 260px;
        align-items: flex-end;
    }

    .promo__image_type_practice-area {
        height: 360px;
        opacity: 1;
    }
}

@media screen and (max-width: 500px) {
    .promo__title {
        font-size: 21px;
    }
}

@media screen and (max-width: 370px) {
    .promo__title {
        margin-bottom: 0;
    }
}