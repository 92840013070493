.block {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    width: 78.8%;
    border-radius: 21px;
    background-color: var(--gray-200);
    margin: auto;
}

.block_type_default {
    display: grid;
    align-items: center;
}

.block_type_advertisement {
    display: flex;
    flex-direction: row;
    min-height: 280px;
    margin-bottom: 100px;
}

.block_type_form {
    padding: 40px 70px 40px 50px;
}

.block__background {
    align-self: end;
    max-height: 240px;
}

.block__container_type_advertisement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px 30px 0 50px;
}

.block__paragraph_type_advertisement {
    background: linear-gradient(to right, var(--gray-400), var(--gray-300));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-align: left;
    font-weight: 300;
    line-height: 115%;
    font-size: 30px;
    margin: 0 0 20px;
}

@media screen and (max-width: 1450px) {
    .block__container_type_advertisement {
        padding-top: 80px;
        padding-left: 20px;
    }

    .block__background {
        max-height: 200px;
    }

    .block__paragraph_type_advertisement {
        font-size: 26px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 1200px) {
    .block_type_advertisement {
        min-height: 240px;
    }

    .block__container_type_advertisement {
        padding-top: 50px;
    }

    .block__background {
        max-height: 200px;
    }

    .block__paragraph_type_advertisement {
        font-size: 22px;
    }
}

@media screen and (max-width: 1024px) {
    .block {
        width: 85%;
    }

    .block_type_form {
        width: 85%;
        padding-right: 60px;
    }

    .block_type_advertisement {
        min-height: 210px;
        margin-bottom: 80px;
    }

    .block__paragraph_type_advertisement {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .block__background {
        max-height: 170px;
    }
}

@media screen and (max-width: 900px) {
    .block_type_advertisement {
        min-height: 200px;
    }

    .block__paragraph_type_advertisement {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .block__container_type_advertisement {
        padding-top: 60px;
    }

    .block__background {
        max-height: 140px;
    }
}

@media screen and (max-width: 768px) {
    .block {
        width: 90%;
    }

    .block_type_form {
        width: 90%;
        padding-left: 40px;
        padding-right: 20px;
    }

    .block_type_advertisement {
        min-height: 160px;
    }

    .block__background {
       display: none;
    }

    .block__container_type_advertisement {
        min-height: 0;
        padding-top: 30px;
        padding-bottom: 20px;
        justify-content: space-between;
        gap: 25px;
    }

    .block__paragraph_type_advertisement {
        font-size: 20px;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 600px) {
    .block_type_form {
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 500px) {
    .block {
        width: 95%;
    }

    .block_type_advertisement {
        margin-bottom: 40px;
    }

    .block__container_type_advertisement {
        gap: 20px;
    }

    .block__paragraph_type_advertisement {
        font-size: 18px;
        margin-bottom: 0;
    }
}