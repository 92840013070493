.employee-list__list {
    width: 78.8%;
    list-style: none;
    padding: 0;
    margin: 0 auto;
}

.employee-list__list_type_gallery {
    justify-content: center;
    width: 100%;
    margin-bottom: 100px;
}

.employee-list__list_type_widget {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.employee-list__list_type_gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 390px));
    grid-auto-rows: auto;
    gap: 110px 40px;
}

.employee-list__title-container {
    display: flex;
    gap: 70px;
}

.section__container_employee-list-widget .swiper {
    overflow: visible;
}

.swiper-team-wrapperhorizontal {
    overflow: hidden;
    display: flex;
    justify-content: center;
    gap: 50px;
}

.swiper-slide > div {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

@media screen and (max-width: 950px) {
    .employee-list__list_type_gallery {
        grid-template-columns: repeat(auto-fit, 310px);
        grid-template-rows: none;
    }
}

@media screen and (max-width: 768px) {
    .employee-list__list_type_gallery {
        grid-template-columns: none;
    }
}