.news-poster {
    display: flex;
    gap: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    bottom: 2px solid var(--gray-400);
    transition: opacity .13s cubic-bezier(0, 0, .23, 1);
    cursor: pointer;
}

.news-poster:hover {
    opacity: 0.8;
}

.news-poster:first-child {
    padding-top: 0;
}

.news-poster:last-child {
    padding-bottom: 0;
}

.news-poster__title {
    text-align: left;
    color: var(--black);
    font-size: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 0 10px;
}

.news-poster__image {
    width: 140px;
    height: 140px;
    border-radius: 19px;
}

.news-poster__container {
    width: 70%;
}

.news-poster__author {
    position: relative;
    text-align: left;
    font-size: 16px;
    padding-left: 25px;
    margin: 0 0 5px;
}

.author-span-black {
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.3s ease;
}

.author-span-black:hover {
    border-bottom-color: black;
}

.news-poster__author::before {
    position: absolute;
    top: 4px;
    left: 0;
    width: 14px;
    height: 14px;
    content: "";
    background-image: url("../../images/PersonIcon.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.news-poster__reading-time {
    position: relative;
    text-align: left;
    font-size: 16px;
    padding-left: 25px;
    margin: 0;
}

.news-poster__reading-time::before {
    position: absolute;
    top: 4px;
    left: 0;
    width: 14px;
    height: 14px;
    content: "";
    background-image: url("../../images/read-time.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

@media screen and (max-width: 1024px) {
    .news-poster__title {
        font-size: 20px;
    }

    .news-poster__image {
        width: 100px;
        height: 100px;
    }

    .gallery-poster {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 1024px) {
    .news-poster__title {
        font-size: 18px;
    }

    .news-poster__image {
        width: 90px;
        height: 90px;
    }

    .gallery-poster {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }
}