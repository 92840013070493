.burger-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 520px;
    background-color: var(--gray-200);
    height: 100%;
    position: absolute;
    justify-content: center;
    right: 0;
}

@media screen and (max-width: 520px) {
    .burger-menu {
        width: 100%;
    }
}