.navigation {
    display: flex;
    align-items: center;
}

.navigation__list {
    display: flex;
    gap: 50px;
    align-items: center;
    list-style: none;
    padding: 0;
}

.navigation__link {
    font-weight: 400;
    font-size: 18px;
    color: var(--gray-400);
    text-decoration: none;
    transition: opacity .13s cubic-bezier(0, 0, .23, 1);
}

.navigation__link:hover {
    opacity: 0.6;
}

.navigation__link_active {
    color: var(--primary);
    font-weight: 500;
}

.navigation__btn-open-burger {
    width: 35px;
    height: 27px;
    display: none;
    background-size: contain;
    background-image: url('../../images/btn-open-burger.svg');
    background-repeat: no-repeat;
}

@media screen and (max-width: 1024px) {
    .navigation__list {
        gap: 20px;
    }

    .navigation__link {
        font-size: 16px;
    }

    .navigation__list_type_footer {
        gap: 15px;
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .navigation__list {
        flex-direction: column;
        margin: 0;
        gap: 10px;
    }

    .navigation__list_type_menu {
        display: none;
    }

    .navigation__list_type_footer {
        display: none;
    }

    .navigation__btn-open-burger {
        width: 30px;
        height: 30px;
        display: block;
    }

    .navigation__btn-open-burger_type_footer {
        display: none;
    }

    .navigation__list_type_footer {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .navigation__btn-open-burger {
        width: 22px;
        height: 22px;
    }
}