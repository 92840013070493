.employee-card {
    width: 430px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #F3F3F3;
    border-radius: 20px;
    cursor: pointer;
}

.employee-card_type_gallery {
    width: 390px;
}

.employee-card__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 65% 30px 25px 15%;
    height: 100%;
}

.employee-card__container_type_gallery {
    padding-top: 69%;
}

.employee-card__avatar-container {
    width: 250px;
    height: 280px;
    position: absolute;
    top: -10%;
    left: 15%;
    background-color: #D0D0D0;
    border-radius: 20px;
    overflow: hidden;
}

.employee-card__avatar-container_type_gallery {
    width: 280px;
    height: 300px;
    background-image: linear-gradient(to bottom, #C4C4C4, #949494);
    cursor: pointer;
}

.employee-card__avatar {
    position: absolute;
    bottom: 0;
    right: 9%;
    height: 268px;
}

.employee-card__avatar_type_gallery {
    height: 250px;
}

.employee-card__name {
    width: max-content;
    max-width: 100%;
    text-align: left;
    font-weight: 500;
    font-size: 26px;
    line-height: 120%;
    color: var(--black);
    margin: 0 0 4px;
    cursor: auto;
}

.employee-card__position {
    max-width: 100%;
    width: max-content;
}

.employee-card__position_type_widget {
    text-align: left;
    font-weight: 300;
    font-size: 20px;
    color: var(--gray-400);
    margin: 0;
    cursor: auto;
}

.employee-card__position_type_gallery {
    position: absolute;
    font-size: 14px;
    top: 15px;
    left: 12px;
    color: var(--white);
    margin: 0;
    cursor: auto;
}

.employee-card__position-accent_type_gallery {
    position: relative;
    max-width: 100%;
    width: max-content;
    padding: 3px 10px 3px 30px;
    background-color: rgba(105, 105, 105, 0.6);
    border-radius: 13px;
    z-index: 10;
}

.employee-card__position-accent_type_gallery::before {
    position: absolute;
    content: "";
    width: 11px;
    height: 14px;
    top: 21%;
    left: 10px;
    background-size: contain;
    background-image: url('../../images/icon-man.svg');
    background-repeat: no-repeat;
}

.employee-card__employee-info-container {
    display: flex;
    flex-direction: column;
    gap: 21px;
}

.employee-card__subheading {
    max-width: 100%;
    width: max-content;
    font-weight: 600;
    text-align: left;
    color: var(--black);
    cursor: auto;
    margin: 0 0 10px;
}

.employee-card__subheading_education {
    margin-bottom: 10px;
    cursor: auto;
}

.employee-card__key-competencies-list {
    width: max-content;
    max-width: 100%;
    padding-left: 20px;
    list-style: disc;
    margin: 0;
    cursor: auto;
}

.employee-card__key-competencies-item {
    text-align: left;
    font-size: 20px;
    color: var(--black);
    padding-left: 6px;
}

.employee-card__education {
    text-align: left;
    font-size: 20px;
    color: var(--black);
    margin: 0;
    cursor: auto;
}

@media screen and (max-width: 1200px) {
    .employee-card {
        width: 380px;
    }

    .employee-card__container {
        padding-top: 65%;
    }

    .employee-card__avatar-container {
        width: 220px;
        height: 250px;
    }

    .employee-card__avatar {
        height: 230px;
    }

    .employee-card__avatar_type_gallery {
        height: 200px;
    }
}

@media screen and (max-width: 950px) {
    .employee-card_type_gallery {
        width: 310px;
    }

    .employee-card__container_type_gallery {
        padding-left: 13%;
    }

    .employee-card__avatar-container_type_gallery {
        width: 190px;
        height: 220px;
        left: 13%;
    }

    .employee-card__position_type_gallery {
        display: none;
    }

    .employee-card__position-accent_type_gallery {
        font-size: 14px;
    }

    .employee-card__name_type_gallery {
        font-size: 24px;
    }

}

@media screen and (max-width: 500px) {
    .employee-card {
        width: 300px;
    }

    .employee-card__container {
        padding-top: 60%;
        padding-right: 30px;
    }

    .employee-card__avatar-container {
        width: 190px;
        height: 220px;
    }

    .employee-card__avatar {
        height: 200px;
    }

    .employee-card__name {
        font-size: 20px;
    }

    .employee-card__position_type_widget {
        font-size: 16px;
    }

    .employee-card__subheading {
        font-size: 18px;
        margin-bottom: 5px;
    }

    .employee-card__key-competencies-item {
        font-size: 16px;
    }

    .employee-card__subheading_education {
        font-size: 18px;
    }

    .employee-card__education {
        font-size: 16px;
    }
}