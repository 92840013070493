.contact-us {
    box-sizing: border-box;
    max-width: 1280px;
    width: 78.8%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    border-radius: 20px;
    background-color: var(--gray-200);
    padding: 50px;
    margin: 0 auto 100px;
}

.contact-us__title {
    width: max-content;
    text-align: left;
    position: relative;
    font-size: 32px;
    color: var(--black);
    margin: 0;
}

.contact-us__title::after {
    position: absolute;
    top: 25%;
    right: -52px;
    content: "";
    background-image: url("../../images/contact-us-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
}

.contact-us__details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
}

.contacts-us__map-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.contact-us__map-wrapper {
    position: relative;
}

.contact-us__map {
    width: 100%;
    height: 350px;
}

.contact-us__adress {
    box-sizing: border-box;
    text-align: left;
    width: 75%;
    padding: 19px 30px;
    border-radius: 12px;
    font-size: 18px;
    color: var(--white);
    background-color: var(--primary);
    margin: 0;
}

.contact-us__cities-list {
    width: 75%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 20%);
    grid-auto-rows: 50px;
    column-gap: 10px;
    row-gap: 20px;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
}

.contact-us__cities-list_active {
    background-color: var(--primary);
}

.contact-us__city {
    width: 100%;
    font-size: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--black);
    background-color: transparent;
    border: 1px solid var(--primary);
    border-radius: 12px;
    padding: 10px 0;
    margin: 0;
    cursor: pointer;
}

.contact-us__city_active {
    font-weight: 500;
    color: var(--white);
    background-color: var(--primary);
}

.contacts-us__contacts-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.contacts-us__description {
    height: 350px;
    text-align: left;
    font-size: 22px;
    line-height: 140%;
    color: var(--black);
    margin: 0;
}

.contacts-us__email-credentials-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.contact-us__email-credential {
    text-align: left;
    font-weight: 500;
    font-size: 24px;
    line-height: 115%;
    color: var(--black);
    margin: 0;
}

.contacts-us__email-input {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    font-size: 18px;
    line-height: 145%;
    color: var(--black);
    background-color: var(--gray-100);
    border: none;
    border-radius: 10px;
    padding: 15px 30px;
}

.contacts-us__email-input::placeholder {
    font-weight: 300;
    color: var(--gray-600);
}

.contacts-us__email-input::after {
    position: absolute;
    top: 25%;
    right: 51px;
    content: "";
    width: 21px;
    height: 21px;
    border: none;
}

@media screen and (max-width: 1024px) {
    .contact-us {
        width: 85%;
        padding: 40px;
        gap: 40px;
        margin-bottom: 80px;
    }

    .contact-us__title {
        font-size: 30px;
    }

    .contact-us__title::after {
        top: 15%;
        right: -55px;
        width: 26px;
        height: 26px;
    }

    .contact-us__city {
        font-size: 18px;
    }

    .contacts-us__description {
        font-size: 21px;
    }

    .contact-us__adress {
        font-size: 16px;
    }

    .contact-us__email-credential {
        font-size: 16px;
    }

    .contacts-us__email-input {
        font-size: 18px;
        padding: 10px 20px;
    }
}

@media screen and (max-width: 768px) {
    .contact-us {
        width: 90%;
        gap: 30px;
        padding: 25px 30px;
    }

    .contact-us__title {
        font-size: 24px;
    }

    .contact-us__title::after {
        width: 24px;
        height: 24px;
        right: -45px;
    }

    .contact-us__adress {
        width: 80%;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .contact-us__cities-list {
        width: 80%;
    }

    .contact-us__city {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }
}

@media screen and (max-width: 500px) {
    .contact-us {
        width: 96%;
        gap: 25px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .contact-us__title {
        font-size: 22px;
    }

    .contact-us__title::after {
        width: 22px;
        height: 22px;
        right: -35%;
    }

    .contact-us__map {
        height: 250px;
    }

    .contact-us__adress {
        width: 100%;
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .contact-us__cities-list {
        width: 100%;
    }

    .contact-us__city {
        font-size: 12px;
    }
}

@media screen and (max-width: 400px) {
    .contact-us__city {
        font-size: 10px;
    }
}