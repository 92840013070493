.button_type_primary {
    display: block;
    position: relative;
    z-index: 2;
    min-width: 180px;
    min-height: 50px;
    font-weight: 400;
    font-size: 20px;
    color: var(--white);
    background-color: var(--primary);
    border-radius: 19px;
}

.button_type_oval {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    text-align: center;
    min-width: 85px;
    min-height: 40px;
    color: var(--white);
    background-color: var(--primary);
    border-radius: 19px;
}

.button_type_accordion {
    font-weight: 300;
    width: 60px;
    height: 40px;
    font-size: 30px;
    color: var(--white);
    background-color: var(--primary);
    border-radius: 10px;
}

.button {
    transition: opacity .13s cubic-bezier(0, 0, .23, 1);
}

.button:hover {
    opacity: 0.8;
}

.input {
    font-family: "Open Sans", sans-serif;
    width: 100%;
    border: none;
    border-radius: 10px;
    font-weight: 300;
    font-size: 18px;
    color: var(--black);
    background-color: var(--gray-100);
    padding: 20px 40px;
}

.input::placeholder {
    font-weight: 400;
    color: var(--gray-400);
    letter-spacing: 1px;
}

.input_type_email {
    position: relative;
}

.input_type_email::after {
    position: absolute;
    content: "";
    right: 10px;
    top: 40%;
    width: 24px;
    height: 24px;
    background-image: url(../../images/EmailLetter.svg);
    background-repeat: no-repeat;
}

.input_type_file {
    position: relative;
    cursor: pointer;
}


.pagination {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
}

.pagination__pages {
    font-weight: 300;
    font-size: 18px;
    color: var(--black);
    margin: 0;
}

.pagination__previous-slide {
    width: 60px;
    height: 10px;
    background-repeat: no-repeat;
    background-size: contain;
}

.pagination__next-slide {
    width: 60px;
    height: 10px;
    background-repeat: no-repeat;
    background-size: contain;
}

@media screen and (max-width: 1024px) {
    .button_type_primary {
        min-width: 140px;
        min-height: 40px;
        font-size: 18px;
    }
}

@media screen and (max-width: 900px) {
    .button_type_primary {
        display: inline;
    }
}

@media screen and (max-width: 768px) {
    .button_type_oval {
        min-width: 65px;
        min-height: 35px;
        font-size: 14px;
    }

    .input {
        padding: 10px 30px;
    }
}

@media screen and (max-width: 500px) {
    .button_type_oval {
        min-width: 57px;
        min-height: 27px;
        font-size: 12px;
    }

    .button_type_primary {
        min-width: 120px;
        min-height: 30px;
        font-size: 14px;
    }
}