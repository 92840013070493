.service-accordion {
    margin-bottom: 10px;
}

.service-accordion:last-child {
    margin-bottom: 0;
}

.service-accordion__header {
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
}

.service-accordion__numeration {
    font-weight: 500;
    font-size: 35px;
    line-height: 115%;
    color: var(--gray-300);
    margin: 0;
}

.service-accordion__title {
    text-align: left;
    font-weight: 300;
    font-size: 24px;
    line-height: 115%;
    color: var(--black);
    margin: 0;
}

.service-accordion__content {
    list-style: none;
    padding: 0;
}

.service-accordion__paragraph {
    text-align: left;
    position: relative;
    font-size: 22px;
    line-height: 115%;
    color: var(--gray-400);
    padding-left: 50px;
    margin: 0 0 10px;
}

.service-accordion__paragraph:first-child {
    margin-top: 20px;
}

.service-accordion__paragraph:last-child {
    margin-bottom: 50px;
}

.service-accordion__paragraph::before {
    position: absolute;
    content: "";
    background-image: url("../../images/icon-dash.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    top: 2px;
    left: 2px;
}

.service-accordion__icon {
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
}

@media screen and (max-width: 1024px) {
    .service-accordion__numeration {
        font-size: 32px;
    }

    .service-accordion__title {
        font-size: 20px;
    }

    .service-accordion__paragraph {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .service-accordion__paragraph::before {
        width: 20px;
        height: 20px;
        top: -2px;
        left: 5px;
    }
}

@media screen and (max-width: 768px) {
    .service-accordion__header {
        margin-bottom: 15px;
        gap: 20px;
    }

    .service-accordion__numeration {
        font-size: 29px;
    }

    .service-accordion__title {
        font-size: 18px;
    }

    .service-accordion__paragraph {
        font-size: 14px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 500px) {
    .service-accordion__header {
        margin-bottom: 15px;
        gap: 20px;
    }

    .service-accordion__numeration {
        font-size: 29px;
    }

    .service-accordion__title {
        font-size: 18px;
    }

    .service-accordion__paragraph {
        font-size: 14px;
        margin-bottom: 10px;
    }
}