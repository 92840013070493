.practice-areas-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.practice-areas-list_type_main {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(375px, 48%));
    grid-template-rows: repeat(auto-fill, minmax(320px, 350px));
    justify-content: space-between;
    margin-top: 20px;
    gap: 35px 20px;
}

.practice-areas-list__item-text-container_type_main {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    z-index: 2;
}

.practice-areas-list__item_type_main {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 40px 170px 30px 50px;
    background-color: var(--gray-200);
    border-radius: 21px;
    overflow: hidden;
}

.practice-areas-list__item-title_type_main {
    text-align: left;
    font-weight: 500;
    font-size: 32px;
    line-height: 115%;
    color: var(--black);
    margin: 0;
}

.practice-areas-list__item-description_type_main {
    text-align: left;
    font-weight: 300;
    font-size: 18px;
    color: var(--gray-500);
    margin: 0 0 20px;
}

.practice-areas-list__item-background {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
    border-top-right-radius: 21px;
    border-bottom-right-radius: 21px;
    z-index: 1;
}

.practice-areas-list__item-background-overlay {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 101%;
    height: 102%;
    background: linear-gradient(to right, rgba(244, 244, 244, 1) 15%, transparent);
    border-top-right-radius: 21px;
    border-bottom-right-radius: 21px;
}

.practice-areas-list__item_type_additional {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right, transparent, #232323, transparent);
    border-image-slice: 1;
    padding: 25px 0;
}

.practice-areas-list__item-text-container_type_additional {
    display: flex;
    align-items: center;
    gap: 56px;
}

.practice-areas-list__item-numeration_type_additional {
    font-weight: 500;
    font-size: 35px;
    line-height: 115%;
    color: var(--gray-300);
    margin: 0;
}

.practice-areas-list__item-title_type_additional {
    text-align: left;
    font-weight: 300;
    font-size: 30px;
    color: var(--black);
    margin: 0;
}

@media screen and (max-width: 1180px) {
    .practice-areas-list_type_main {
        grid-template-rows: repeat(auto-fit, minmax(270px, 330px));
    }

    .practice-areas-list__item_type_main {
        box-sizing: border-box;
        height: 100%;
        padding-right: 80px;
        justify-content: space-between;
    }

    .practice-areas-list__item-title_type_main {
        font-size: 26px;
    }
}

@media screen and (max-width: 1024px) {
    .practice-areas-list_type_main {
        gap: 25px 14px;
    }
}

@media screen and (max-width: 900px) {
    .practice-areas-list_type_main {
        grid-template-columns: repeat(auto-fit, 48%);
        grid-template-rows: repeat(auto-fit, 290px);
    }

    .practice-areas-list__item_type_additional {
        padding: 15px 0;
    }

    .practice-areas-list__item_type_main {
        padding-right: 40px;
    }

    .practice-areas-list__item-text-container_type_additional {
        gap: 36px;
    }

    .practice-areas-list__item-title_type_main {
        font-size: 24px;
    }

    .practice-areas-list__item-description_type_main {
        font-size: 16px;
    }

    .practice-areas-list__item-numeration_type_additional {
        font-size: 28px;
    }

    .practice-areas-list__item-title_type_additional {
        font-size: 24px;
        padding: 0;
    }
}

@media screen and (max-width: 768px) {
    .practice-areas-list_type_main {
        grid-template-rows: none;
        grid-auto-rows: minmax(max-content, 270px);
    }

    .practice-areas-list__item_type_additional {
        padding: 20px 0;
    }

    .practice-areas-list__item_type_main {
        padding-left: 30px;
    }

    .practice-areas-list__item-title_type_main {
        font-size: 22px;
    }

    .practice-areas-list__item-text-container_type_additional {
        gap: 36px;
    }

    .practice-areas-list__item-numeration_type_additional {
        font-size: 28px;
    }

    .practice-areas-list__item-title_type_additional {
        max-width: 300px;
        font-size: 24px;
        padding: 0;
    }
}

@media screen and (max-width: 650px) {
    .practice-areas-list_type_main {
        grid-template-columns: 90%;
        grid-auto-rows: minmax(250px, 280px);
        justify-content: center;
    }
}

@media screen and (max-width: 500px) {
    .practice-areas-list__item_type_main {
        padding-left: 30px;
        padding-right: 80px;
    }

    .practice-areas-list__item-title_type_additional {
        font-size: 20px;
    }

    .practice-areas-list__item-description_type_main {
        font-size: 14px;
    }

    .practice-areas-list__item_type_additional {
        padding: 10px 0;
    }

    .practice-areas-list__item-text-container_type_additional {
        gap: 26px;
    }

    .practice-areas-list__item-numeration_type_additional {
        font-size: 22px;
    }

    .practice-areas-list__item-title_type_additional {
        max-width: 300px;
        font-size: 18px;
        padding: 0;
    }
}