.section_color_white {
    background-color: var(--white);
}

.section_color_gray {
    background-color: var(--gray-200);
}

/* .section_main-promo {
    margin-bottom: 80px;
} */

.section_widget-news-list {
    margin-bottom: 100px;
}

.section_poster-news-list {
    margin-bottom: 50px;
}

.section__title {
    max-width: 256px;
    text-align: left;
    font-weight: 300;
    font-size: 18px;
    line-height: 145%;
    color: var(--gray-500);
    margin: 0 0 20px;
}

.section__title_employee-list {
    margin-bottom: 0;
}

.section__container {
    max-width: 1280px;
}

.section__container_main-promo {
    position: relative;
    width: 78.8%;
    min-height: 260px;
    display: flex;
    padding: 130px 0 50px;
    margin: auto;
}

.section__container_practice-areas-add {
    width: 78.8%;
    display: flex;
    flex-direction: column;
    margin: 0 auto 100px;
}

.section__container_faq {
    width: 78.8%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.section__container_employee-list-widget {
    width: 78.8%;
    display: flex;
    gap: 120px;
    flex-direction: column;
    margin: auto;
    overflow: hidden;
    margin-bottom: 100px;
}

.section__container_employee-list-gallery {
    width: 90%;
    display: flex;
    gap: 120px;
    flex-direction: column;
    margin: 0 auto 80px;
}

.section__container_widget-news-list {
    width: 78.8%;
    margin: auto;
}

.section__container_gallery-news-list {
    width: 99%;
    margin: auto;
}

.section__container_poster-news-list {
    width: 99%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.section__container_practice-areas-main {
    width: 78.8%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

@media screen and (max-width: 1024px) {
    .section_widget-news-list {
        margin-bottom: 80px;
    }

    .section__container_main-promo {
        padding-top: 100px;
        padding-bottom: 10px;
        width: 85%;
    }

    .section__container_practice-areas-main {
        width: 85%;
    }

    .section__container_practice-areas-add {
        width: 85%;
        margin-bottom: 80px;
    }

    .section__container_faq {
        width: 85%;
    }

    .section__container_widget-news-list {
        width: 85%;
    }

    .section__title {
        max-width: 200px;
        font-size: 18px;
    }

    .section__container_employee-list-widget {
        width: 85%;
        gap: 50px;
        margin-bottom: 80px;
    }
}

@media screen and (max-width: 900px) {
    .section__container_main-promo {
        flex-direction: column-reverse;
        align-items: center;
        gap: 30px;
        padding-top: 0;
    }
}

@media screen and (max-width: 768px) {
    .section__container_main-promo {
        width: 90%;
    }

    .section__container_practice-areas-main {
        width: 90%;
    }

    .section__container_practice-areas-add {
        width: 90%;
    }

    .section__container_faq {
        width: 90%;
    }

    .section__title {
        font-size: 16px;
    }

    .section__container_employee-list-gallery {
        gap: 60px;
    }

    .section__container_employee-list-widget {
        width: 90%;
    }

    .section__container_widget-news-list {
        width: 90%;
    }
}

@media screen and (max-width: 500px) {
    .section_main-promo {
        margin-bottom: 40px;
    }

    .section__container_practice-areas-main {
        width: 96%;
    }

    .section_practice-areas-add {
        margin-bottom: 50px;
    }

    .section__container_main-promo {
        width: 96%;
    }

    .section__container_practice-areas-add {
        width: 96%;
    }

    .section__container_faq {
        width: 96%;
    }

    .section__container_employee-list-widget {
        width: 96%;
    }

    .section__container_widget-news-list {
        width: 96%;
    }

    .section__title {
        max-width: 150px;
        font-size: 12px;
        margin-bottom: 5px;
    }

    .section__container_widget-news-list {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}