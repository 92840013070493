.logo__picture {
    width: 155px;
    height: 90px;
}

@media screen and (max-width: 1024px) {
    .logo__picture {
        width: 134px;
        height: 69px;
    }
}

@media screen and (max-width: 768px) {
    .logo__picture {
        width: 114px;
        height: 70px;
    }
}

@media screen and (max-width: 500px) {
    .logo__picture {
        width: 80px;
        height: 50px;
    }
}