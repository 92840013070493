.header {
    padding-top: 25px;
}

.header_border {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--gray-300);
}

.header_color_white {
    background-color: var(--white);
}

.header_color_gray {
    background-color: var(--gray-200);
}

.header__container {
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    margin: auto;
}

.header__container_page_main {
    max-width: 1280px;
    width: 78.8%;
}

.header__container_page_team {
    max-width: 1280px;
    width: 90%;
}

@media screen and (max-width: 1024px) {
    .header__container_page_main {
        width: 85%;
    }
}

@media screen and (max-width: 768px) {
    .header {
        padding-bottom: 15px;
    }
}