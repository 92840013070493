.news-inner {
    position: relative;
    box-sizing: border-box;
    max-width: 1280px;
    width: 95%;
    background-color: var(--gray-200);
    padding: 40px 50px;
    border-radius: 20px;
    margin: 80px auto 80px;
}

.news-inner__image {
    object-fit: contain;
    margin-bottom: 40px;
}

.article__title {
    text-align: left;
    font-size: 28px;
    color: var(--black);
    margin: 0 0 40px;
}

.article__content {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.article__text {
    width: 70%;
    text-align: justify;
    font-size: 18px;
    color: var(--black);
}

.article__text p:first-child {
    margin-top: 0;
}

.article__subtitle {
    text-align: left;
    font-size: 21px;
    color: var(--black);
    margin: 10px 0 5px;
}

.article__author {
    width: 220px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    transition: opacity .13s cubic-bezier(0, 0, .23, 1);
}

.article__author:hover {
    opacity: 0.8;
}

.article__avatar-container {
    position: relative;
    width: 220px;
    height: 220px;
    background-color: #D0D0D0;
    border-radius: 20px;
    overflow: hidden;
}

.article__avatar {
    position: absolute;
    bottom: 0;
    right: 17%;
    height: 90%;
}

.article__author-name {
    position: relative;
    text-align: left;
    font-size: 16px;
    padding-left: 25px;
    margin: 0 0 5px;
}

.article__author-name::before {
    position: absolute;
    top: 2px;
    left: 0;
    width: 14px;
    height: 14px;
    content: "";
    background-image: url("../../images/author.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.article__reading-time {
    position: relative;
    text-align: left;
    font-size: 16px;
    padding-left: 25px;
    margin: 0;
}

.article__reading-time::before {
    position: absolute;
    top: 2px;
    left: 0;
    width: 14px;
    height: 14px;
    content: "";
    background-image: url("../../images/reading-time.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

@media screen and (max-width: 1024px) {
    .article__title {
        font-size: 26px;
    }

    .article__author {
        width: 180px;
    }

    .article__avatar-container {
        width: 180px;
        height: 180px;
    }

    .article__author-name {
        font-size: 14px;
        padding-left: 20px;
    }

    .article__author-name::before {
        width: 12px;
        height: 12px;
    }

    .article__reading-time {
        font-size: 14px;
        padding-left: 20px;
    }

    .article__reading-tim::before {
        width: 12px;
        height: 12px;
    }
}

@media screen and (max-width: 900px) {
    .news-inner {
        padding-left: 40px;
        padding-right: 40px;
    }

    .article__title {
        font-size: 22px;
    }

    .article__subtitle {
        font-size: 18px;
    }

    .article__paragraph {
        font-size: 14px;
    }

    .article__text {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .news-inner {
        padding-top: 30px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    .article__title {
        font-size: 21px;
    }

    .article__author {
        width: 130px;
    }

    .article__avatar-container {
        width: 130px;
        height: 130px;
    }

    .article__author-name {
        font-size: 14px;
    }

    .article__text {
        width: 73%;
        font-size: 15px;
    }
}

@media screen and (max-width: 500px) {
    .news-inner {
        padding-right: 10px;
    }

    .article__title {
        font-size: 18px;
        margin-bottom: 25px;
    }

    .article__author {
        width: 100px;
    }

    .article__avatar-container {
        width: 100px;
        height: 100px;
    }

    .article__author-name {
        font-size: 12px;
    }

    .article__reading-time {
        font-size: 12px;
    }

    .article__text {
        font-size: 14px;
    }
}