.news-card_type_widget {
    border-radius: 20px;
    box-sizing: border-box;
    max-width: 500px;
    height: 320px;
    background-color: var(--gray-200);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    padding: 60px 40px 40px;
}

.news-card_type_gallery {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    border-radius: 19px;
    background-size: cover;
    background-repeat: no-repeat;
    padding-left: 80px;
    padding-right: 50px;
    padding-bottom: 80px;
    background-color: var(--gray-400);
    transition: opacity .13s cubic-bezier(0, 0, .23, 1);
    z-index: 1;
}

.news-card_type_gallery:hover {
    opacity: 0.8;
    cursor: pointer;
}


.news-card_type_gallery-little {
    background-size: cover;
    padding-left: 30px;
    padding-bottom: 30px;
}

.news-card__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(93, 93, 93, 0.6) 15%, transparent);
    border-radius: 19px;
    z-index: 0;
}

.news-card__text-container {
    position: relative;
    z-index: 10;
}

.news-card__text-container_type_widget {
    display: flex;
    flex-direction: column;
}

.news-card__text-container_type_gallery {
    width: 100%;
}

.news-card__date_type_widget {
    text-align: left;
    font-weight: 300;
    font-size: 16px;
    color: var(--gray-600);
    margin: 0 0 10px;
}

.news-card__title_type_widget {
    text-align: left;
    font-weight: 500;
    font-size: 26px;
    color: var(--black);
    margin: 0 0 30px;
}

.news-card__title_type_gallery {
    text-align: left;
    font-weight: 500;
    font-size: 28px;
    color: var(--white);
    margin: 0 0 10px;
}

.news-card__title-mini_type_gallery {
    font-size: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.news-card__description_type_widget {
    text-align: left;
    font-weight: 300;
    font-size: 20px;
    color: var(--gray-400);
    margin: 0;
}

.news-card__description-container_type_gallery {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.news-card__info-container {
    min-width: 300px;
    width: 80%;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.author-span {
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.3s ease;
}

.author-span:hover {
    border-bottom-color: white;
}

.news-card__author_type_gallery {
    position: relative;
    font-size: 20px;
    color: var(--white);
    padding-left: 30px;
    margin: 0;
}

.news-card__author_type_gallery::before {
    position: absolute;
    left: 0;
    top: 15%;
    content: "";
    background-image: url("../../images/PersonIcon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 21px;
    height: 21px;
}

.news-card__author-mini_type_gallery {
    padding-left: 30px;
    font-size: 18px;
}

.news-card__author-mini_type_gallery::before {
    width: 18px;
    height: 18px;
}

.news-card__reading-time_type_gallery {
    position: relative;
    font-size: 20px;
    color: var(--white);
    padding-left: 25px;
    margin: 0;
}

.news-card__reading-time_type_gallery::before {
    position: absolute;
    left: 0;
    top: 20%;
    content: "";
    background-image: url("../../images/reading-time.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
}

.news-card__btn-read {
    width: 35px;
    height: 35px;
    background-image: url("../../images/read-icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
}

.news-card__btn-read-mini {
    width: 28px;
    height: 28px;
}

@media screen and (max-width: 1024px) {
    .news-card_type_widget {
        max-width: 400px;
    }

    .news-card_type_gallery-little {
        width: 49%;
        padding-left: 25px;
        padding-right: 30px;
    }

    .news-card__info-container {
        width: 58%;
    }
}

@media screen and (max-width: 900px) {
    .news-card__title_type_gallery {
        font-size: 26px;
    }

    .news-card__title-mini_type_gallery {
        font-size: 24px;
    }

    .news-card__author_type_gallery {
        font-size: 18px;
    }

    .news-card__author_type_gallery::before {
        width: 20px;
        height: 18px;
    }

    .news-card__reading-time_type_gallery {
        font-size: 18px;
    }

    .news-card__reading-time_type_gallery::before {
        width: 16px;
        height: 16px;
    }
}

@media screen and (max-width: 768px) {
    .news-card_type_gallery {
        padding-left: 40px;
        padding-bottom: 40px;
    }

    .news-card_type_gallery-little {
        padding-left: 30px;
        padding-bottom: 20px;
        min-height: 300px;
    }

    .news-card__title_type_gallery {
        font-size: 24px;
    }

    .news-card__title-mini_type_gallery {
        font-size: 20px;
    }

    .news-card__author_type_gallery {
        font-size: 16px;
    }

    .news-card__author-mini_type_gallery {
        font-size: 14px;
    }

    .news-card__author-mini_type_gallery::before {
        width: 16px;
        height: 14px;
    }

    .news-card_type_widget {
        padding: 50px 30px 30px;
    }

    .news-card__title_type_widget {
        font-size: 22px;
    }

    .news-card__date_type_widget {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .news-card__description_type_widget {
        font-size: 16px;
    }
}

@media screen and (max-width: 500px) {
    .news-card_type_widget {
        max-width: 300px;
        padding-top: 30px;
    }

    .news-card__date_type_widget {
        font-size: 12px;
    }

    .news-card__title_type_widget {
        font-size: 20px;
        margin-bottom: 15px;
    }
}