.employee-block {
    box-sizing: border-box;
    position: relative;
    max-width: 1280px;
    width: 78.8%;
    display: flex;
    gap: 40px;
    background: linear-gradient(#F3F3F3, #E0E0E0);
    border-radius: 20px;
    padding: 60px 40px 40px 60px;
    margin: auto;
}

.employee-block__image-container {
    display: flex;
    height: max-content;
    align-items: center;
    justify-content: center;
    background-color: var(--gray-300);
    border-radius: 19px;
}

.employee-block__image {
    width: 200px;
    margin: 0 auto;
    padding: 30px 40px 0;
}

.employee-block__text-container {
    width: 58%;
}

.employee-block__name {
    text-align: left;
    font-weight: 500;
    font-size: 30px;
    color: var(--black);
    margin: 0 0 20px;
}

.employee-block__credentials-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0;
    margin: 0 0 35px;
}

.employee-block__credentials-item {
    text-align: left;
    position: relative;
    font-size: 18px;
    color: var(--black);
    padding-left: 35px;
    margin: 0;
}

.employee-block__credentials-item::before {
    position: absolute;
    content: "";
    left: 0;
    top: 3px;
    width: 18px;
    height: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.employee-block__credentials-item_position::before {
    background-image: url(../../images/bag.svg);
}

.employee-block__credentials-item_city::before {
    background-image: url(../../images/city.svg);
}

.employee-block__about {
    font-weight: 300;
    text-align: left;
    line-height: 115%;
    text-decoration: underline;
    margin: 0 0 60px;
}

.employee-block__competencies-container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 50px;
}

.employee-block__competencies-heading {
    text-align: left;
    font-weight: 500;
    color: var(--black);
    margin: 0 0 15px;
}

.employee-block__competencies-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 15px;
    color: var(--black);
    margin: 0 0 0;
}

.employee-block__competencies-list_education {
    list-style: none;
    padding: 0;
}

.employee-block__competencies-list-item {
    text-align: left;
    font-size: 18px;
    margin: 0;
}

.employee-block__description {
    text-align: justify;
    font-size: 18px;
    color: var(--black);
    margin: 0;
}

.bread-crumbs__team-inner {
    position: absolute;
    width: 70%;
    top: -30px;
    left: 0;
}

@media screen and (max-width: 1180px) {
    .employee-block {
        padding-right: 50px;
    }

    .employee-block__text-container {
        width: 50%;
    }

    .employee-block__competencies-container {
        flex-direction: column;
    }

    .employee-block__competencies-list {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 1024px) {
    .employee-block {
        width: 85%;
    }
}

@media screen and (max-width: 900px) {
    .employee-block {
        flex-direction: column;
        gap: 40px;
        align-items: center;
        padding-left: 50px;
    }

    .employee-block__image {
        position: static;
        width: 40%;
        height: 70%;
    }

    .employee-block__image-container {
        background-color: inherit;
    }

    .employee-block__text-container {
        width: 100%;
    }

    .employee-block__competencies-container {
        gap: 30px;
    }
}

@media screen and (max-width: 768px) {
    .employee-block {
        width: 90%;
        padding-top: 30px;
    }

    .employee-block__name {
        font-size: 26px;
    }

    .employee-block__credentials-item {
        font-size: 16px;
    }

    .employee-block__credentials-item::before {
        width: 16px;
        height: 16px;
    }

    .employee-block__competencies-container {
        margin-bottom: 35px;
    }

    .employee-block__competencies-heading {
        font-size: 20px;
    }

    .employee-block__competencies-list-item {
        font-size: 16px;
    }

    .employee-block__description {
        font-size: 16px;
    }

    .employee-block__description p:first-child {
        margin-top: 0;
    }

    .bread-crumbs__team-inner {
        width: 100%;
        top: -25px;
    }
}

@media screen and (max-width: 500px) {
    .employee-block {
        padding-left: 30px;
        padding-right: 30px;
    }

    .employee-block__image {
        min-width: 250px;
        height: 290px;
    }

    .employee-block__name {
        font-size: 20px;
    }

    .employee-block__credentials-item {
        font-size: 14px;
    }

    .employee-block__credentials-item::before {
        width: 14px;
        height: 14px;
    }

    .employee-block__competencies-heading {
        font-size: 18px;
    }

    .employee-block__competencies-list-item {
        font-size: 14px;
    }

    .employee-block__description {
        font-size: 14px;
    }
}