.services-list {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    width: 78.8%;
    margin: 80px auto 0;
}

.bread-crumbs {
    text-decoration: none;
    text-align: left;
    font-weight: 300;
    font-size: 16px;
    color: var(--gray-400);
    margin: 0 0 5px;
}

.services-list__title {
    width: 40%;
    font-weight: 300;
    font-size: 30px;
    text-align: left;
    color: var(--black);
    margin: 0 0 30px;
}

.services-list__container {
    width: 100%;
    display: grid;
    grid-template-columns: 49% 49%;
    grid-template-rows: repeat(max-content);
    gap: 25px;
    list-style: none;
    padding: 0;
}

@media screen and (max-width: 1024px) {
    .services-list {
        width: 85%;
    }

    .services-list__title {
        font-size: 28px;
        margin-bottom: 15px;
    }
}


@media screen and (max-width: 768px) {
    .services-list {
        width: 90%;
    }

    .services-list__title {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .bread-crumbs {
        font-size: 14px;
    }
}

@media screen and (max-width: 500px) {
    .services-list {
        width: 96%;
    }

    .services-list__container {
        grid-template-columns: auto;
    }

    .services-list__title {
        font-size: 24px;
        margin-bottom: 10px;
        width: 90%;
    }

    .bread-crumbs {
        font-size: 14px;
    }
}