.form {
    display: flex;
    flex-direction: column;
}

.form_type_join-us {
    gap: 20px;
}

.form__title-container {
    display: flex;
    align-items: center;
}

.form__title-container_type_join-us {
    gap: 25px;
}

.form__title {
    font-weight: 500;
    color: var(--black);
    margin: 0;
}

.form__title-join-us {
    font-size: 30px;
    line-height: 115%;
}

.form__title-icon {
    width: 28px;
    height: 28px;
    background-size: contain;
    background-repeat: no-repeat;
}

.form__fieldset {
    display: flex;
    border: none;
    padding: 0;
    margin: 0;
}

.form__fieldset_type_join-us {
    justify-content: space-between;
    align-items: center;
}

.form__input-container {
    display: flex;
}

.form__input-container_type_join-us {
    width: 44%;
    flex-direction: row;
    gap: 30px;
}

.form__btn-next {
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
}

@media screen and (max-width: 1024px) {
    .form__title-join-us {
        font-size: 28px;
    }

    .form__title-icon {
        width: 28px;
        height: 26px;
    }
}

@media screen and (max-width: 768px) {
    .form__title-join-us {
        font-size: 24px;
    }
}

@media screen and (max-width: 600px) {
    .form__fieldset_type_join-us {
        flex-direction: column;
        gap: 10px;
    }

    .form__input-container_type_join-us {
        width: 100%;
    }

    .form__btn-next {
        margin-top: 10px;
    }
}