.footer {
    width: 78.8%;
    max-width: 1280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:  0 auto 30px;
}

.footer__divider {
    display: flex;
    flex-direction: column;
}

.divider {
    width: 2px;
    height: 100px;
    background-color: var(--primary);
}

.footer__thesis {
    text-align: center;
    font-weight: 300;
    font-size: 28px;
    letter-spacing: 5px;
    color: var(--primary);
    margin: 0;
}

.contacts {
    display: none;
    flex-direction: column;
    gap: 5px;
}

.contacts__credential {
    text-align: left;
    font-weight: 500;
    font-size: 24px;
    line-height: 115%;
    color: var(--black);
    margin: 0;
}

@media screen and (max-width: 1024px) {
    .footer {
        width: 85%;
    }

    .footer__thesis {
        font-size: 24px;
    }

    .contacts__credential {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .contacts {
        display: flex;
    }
}

@media screen and (max-width: 500px) {
    .footer__thesis {
        font-size: 16px;
    }

    .contacts__credential {
        font-size: 14px;
    }
}