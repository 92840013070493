.news-list_type_widget {
    width: 150%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 500px);
    grid-template-rows: 330px;
    gap: 30px;
    list-style: none;
    padding: 0;
    margin: auto;
}

.news-list_type_gallery {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 58% 40%;
    grid-template-rows: minmax(500px, 590px);
    margin-bottom: 80px;
}

.news-list_type_poster {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(400px, 500px) minmax(500px, 650px);
    grid-template-rows: 500px;
    column-gap: 40px;
}

.news-list__container_type_poster {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
}

.news-list__flex-container {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.swiper-slide {
    height: auto;
}

@media screen and (max-width: 1024px) {
    .news-list_type_gallery {
        grid-template-columns: 100%;
        grid-template-rows: 450px 450px;
        row-gap: 20px;
    }

    .news-list__flex-container {
        flex-direction: row;
        gap: 15px;
    }

    .news-list_type_poster {
        grid-template-columns: minmax(300px, 400px) minmax(400px, 580px);
        grid-template-rows: 370px;
        column-gap: 30px;
    }
}

@media screen and (max-width: 768px) {
    .news-list_type_gallery {
        grid-template-rows: 400px 400px;
    }

    .news-list_type_poster {
        column-gap: 20px;
    }
}

@media screen and (max-width: 600px) {
    .news-list_type_gallery {
        grid-template-rows: repeat(auto-fill, 400px);
    }
}

@media screen and (max-width: 500px) {
    .news-list_type_gallery {
        grid-auto-rows: 500px;
    }

    .news-list_type_poster {
        display: none;
    }
}