@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
    /* Переменные цвета */
    --primary: #45812B;

    --black: #1F1F1F;
    --white: #FFFF;
    --gray-600: #3E3E3E;
    --gray-500: #606060;
    --gray-400: #5A5A5A;
    --gray-300: #C2C2C2;
    --gray-200: #F4F4F4;
    --gray-100: #E4E4E4;

    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 21px;
    color: #5A5A5A;
    margin: 0;
    overflow-x: hidden;
}

button {
    font-family: "Open Sans", sans-serif;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

a {
    text-decoration: none;
}

code {
    font-family: "Open Sans", sans-serif;
    white-space: normal;
}

.bold {
    font-weight: 500;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.strikethrough {
    text-decoration: line-through;
}

.hidden {
    display: none;
    width: 0;
    height: 0;
}

.accentiate-text {
    font-weight: 500;
}

.main-promo__list {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin-top: 60px;
    margin-bottom: 30px;
    padding-bottom: 12px;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right, #232323, transparent);
    border-image-slice: 1;
}

.main-promo__item {
    font-weight: 300;
    margin: 0;
}

.bread-crumbs {
    display: flex;
    align-items: center;
    text-decoration: none;
    text-align: left;
    font-weight: 300;
    font-size: 16px;
    color: var(--gray-400);
    margin: 0 0 5px;
}

.bread-crumbs__after {
    width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media screen and (max-width: 1450px) {
    .main-promo__item {
        font-size: 18px;
    }
}

@media screen and (max-width: 900px) {
    .main-promo__list {
        margin: 0 auto 50px;
    }
}

@media screen and (max-width: 768px) {
    .main-promo__list {
        max-width: 550px;
        margin-bottom: 30px;
        padding-bottom: 9px;
    }

    .main-promo__item {
        font-size: 15px;
    }
}

@media screen and (max-width: 500px) {
    .main-promo__list {
        max-width: 440px;
        margin-bottom: 0;
    }

    .main-promo__item {
        font-size: 12px;
    }
}

@media screen and (max-width: 370px) {
    .main-promo__list {
        display: none;
    }
}