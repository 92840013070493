.fake-input {
    max-width: 500px;
    cursor: pointer;
    text-align: left;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    width: 100%;
    border: none;
    border-radius: 10px;
    font-weight: 400;
    font-size: 18px;
    color: var(--gray-400);
    background-color: var(--gray-100);
    padding: 20px 40px;
}

@media screen and (max-width: 768px) {
    .fake-input {
        padding: 10px 30px;
    }
}