.page {
  height: 100vh;
  display: flex;
  justify-items: center;
  align-items: center;
}

.error {
  width: 30%;
  display: flex;
  flex-direction: column;
  background-color: var(--gray-200);
  border-radius: 12px;
  padding: 12px 24px;
  margin: 0 auto;
}

.error__code {
  text-align: left;
  color: #45812b;
  font-size: 40px;
  font-weight: 500;
  line-height: 110%;
  justify-self: start;
  margin: 0;
  letter-spacing: 2px;
}

.error__title {
  color: var(--black);
  font-size: 34px;
  font-weight: 600;
  margin: 0;
  text-align: left;
  letter-spacing: 1px;
}