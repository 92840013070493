.current-lang {
    font-size: 16px;
    position: relative;
    color: var(--primary);
    margin-top: 5px;
}

.current-lang:hover {
    opacity: 0.8;
}

.current-lang::after {
    position: absolute;
    top: 43%;
    right: -10px;
    content: "";
    width: 8px;
    height: 8px;
    background-image: url("../../images/arrow-poly.svg");
    background-repeat: no-repeat;
}

.dropdown {
    position: absolute;
    top: -10px;
    left: 0;
    z-index: 10;
    flex-direction: column;
    gap: 5px;
    list-style: none;
    padding: 10px 15px;
    background-color: var(--white);
    border-radius: 20%;
}

.dropdown__item {
    font-size: 14px;
    color: var(--gray-400);
    opacity: 0.8;
    cursor: pointer;
}

.dropdown__item_active {
    color: var(--primary);
}